import numeral from 'numeral';

const moneyValueFormatter = function(params) {
  var number = params.value;
  return numeral(number).format('0,0.00');
}

const numValueFormatter = function(params) {
  var number = params.value;
  return numeral(number).format('0,0');
}

const numValueCellStyle = function(params) {
  return {color: 'green', fontWeight: 'bold'};
}


const tradeRequestGridColumns = [
  // {
  //   field: 'tradeId',
  //   headerName: "TradeId",
  //   cellClass: 'non-number',
  //   width: 110,
  //   headerCheckboxSelection: true,
  //   headerCheckboxSelectionFilteredOnly: true,
  //   checkboxSelection: true,
  //   pinned: 'left',
  // },
  // {
  //   field: 'employee',
  //   headerName: "Employee",
  //   cellClass: 'non-number',
  //   width: 160,
  //   pinned: 'left',
  // },
  {
    field: 'accountName',
    headerName: "Account Holder",
    cellClass: 'non-number',
    width: 150,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    pinned: 'left',
  },
  {
    field: 'tradeDateLabel',
    headerName: 'Trade Date',
    cellClass: 'non-number',
    width: 120,
  },
  // {
  //   field: 'securityId',
  //   headerName: "Security Id",
  //   cellClass: 'non-number',
  //   width: 100,
  // },
  {
    field: 'category',
    headerName: "Category",
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'isPassive',
    headerName: 'IsPassive',
    cellClass: 'non-number',
    width: 80,
    valueGetter: (params) => {
      if (params.data) {
        if (params.data.isPassive === true) {
          return 'Yes';
        } else if (params.data.isPassive === false) {
          return 'No';
        } 
      };
      return '';
    }
  },
  {
    field: 'bbgTicker',
    headerName: "Bbg Ticker",
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'secName',
    headerName: 'Security Name',
    cellClass: 'non-number',
    width: 140,
  },
  {
    field: 'side',
    headerName: 'Side',
    cellClass: 'non-number',
    width: 70,
  },
  {
    field: 'applyQty',
    headerName: 'Apply Qty',
    cellClass: 'number',
    width: 100,
    valueFormatter: function(params) {
      if (params.data.side === 'SHORT') {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
    }
  },
  {
    field: 'actualQty',
    headerName: 'Actual Qty',
    cellClass: 'number',
    width: 100,
    valueFormatter: function(params) {
      var number = params.value;
      if(number === undefined || number === null || number === '') {
        return '';
      }
      if (params.data.side === 'SHORT') {
          return '-' + params.value; // 如果 side 字段为 SHORT，则在 quantity 值前面加上负号
      } else {
          return params.value; // 其他情况下不做处理，直接返回原始值
      }
    }
  },
  {
    field: 'priceType',
    headerName: 'Price Type',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'number',
    width: 80,
  },
  {
    field: 'requestStatusLabel',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatusLabel',
    headerName: 'Approval Status',
    width: 150,
    cellStyle: function(params) {
      if (params.value == 'Approved') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#90EE90'};
      } else if (params.value == 'Rejected') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FF8BA0'};
      } else if (params.value == 'PendingApproval') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FED06C'};
      } else if (params.value == 'PendingFinalApproval') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#FFA53F'};
      }  else if (params.value == 'FinalApproved') {
        return {color: 'white', fontWeight: 'bold', backgroundColor: '#E05C04'};
      } 
    },
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'holdingDays',
    headerName: 'Holding Days',
    cellClass: 'number',
    width: 150,
  },
  {
    field: 'stockMarket',
    headerName: 'Stock Market',
    cellClass: 'non-number',
    width: 240,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'accountNo',
    headerName: "Account No",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'brokerAccount',
    headerName: "Broker Account",
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "previewTradeAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'remark',
    headerName: 'Remark',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'isAbandonedLabel',
    headerName: 'IsAbandoned',
    cellClass: 'non-number',
    width: 150,
  },
];

const giftHospitalityRequestGridColumns = [
  {
    field: 'employee',
    headerName: "Employee",
    cellClass: 'non-number',
    width: 150,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'dateLabel',
    headerName: "Date",
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'type',
    headerName: 'Type',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'giver',
    headerName: 'Giver',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'giverPosition',
    headerName: 'Giver Position',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'giverCompany',
    headerName: 'Giver Company',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'USDAmount',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'localAmount',
    headerName: 'LocalAmount',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    cellClass: 'non-number',
    width: 170,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellClass: 'non-number',
    cellRenderer: "giftAttachmentRenderer",
    width: 150,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 250,
  },
  {
    field: 'requestStatus',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
];

const restrictedSecurityGridColumns = [
  {
    field: 'bbgTicker',
    headerName: 'Bbg Ticker',
    cellClass: 'non-number',
    width: 120,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 200,
  },
  {
    field: 'validFromLabel',
    headerName: 'Valid From',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "validToLabel",
    headerName: 'Valid To',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: "listStatus",
    headerName: 'List Status',
    cellClass: 'non-number',
    width: 120,
  },
  {
    field: 'onListReason',
    headerName: 'On List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'offListReason',
    headerName: 'Off List Reason',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'relatedCompany',
    headerName: 'Related Company',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    cellRenderer: "restrictedSecAttachRenderer",
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestStatus',
    headerName: 'Application Status',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'requestTimeLabel',
    headerName: 'Application Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'feedback',
    headerName: 'Feedback',
    cellClass: 'non-number',
    width: 300,
  },
  {
    field: 'feedbackTimeLabel',
    headerName: 'Feedback Time',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'feedbackBy',
    headerName: 'FeedbackBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalStatus',
    headerName: 'Approval Status',
    width: 150,
    cellStyle: function(params) {
      if (params.value=='Approved') {
          return {color: 'green', fontWeight: 'bold'};
      } else if (params.value=='Rejected') {
        return {color: 'red', fontWeight: 'bold'};
      }
    },
  },
  {
    field: 'approvedBy',
    headerName: 'ApprovedBy',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'approvalTimeLabel',
    headerName: 'Approval Time',
    cellClass: 'non-number',
    width: 150,
  },
];


const CheckHoldingsGridColumns = [
  // {
  //   field: 'accountName',
  //   headerName: 'Account Name',
  //   cellClass: 'non-number',
  //   width: 150,
  // },
  {
    field: 'bbgTicker',
    headerName: 'BBG Ticker',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'secName',
    headerName: 'Sec Name',
    cellClass: 'non-number',
    width: 150,
  },
  {
    field: 'direction',
    headerName: 'Direction',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    cellClass: 'non-number',
    width: 100,
    valueFormatter: numValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'price',
    headerName: 'Price',
    cellClass: 'non-number',
    width: 100,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    cellClass: 'non-number',
    width: 100,
    valueFormatter: moneyValueFormatter,
    cellStyle: numValueCellStyle,
  },
  {
    field: 'currency',
    headerName: 'Currency',
    cellClass: 'non-number',
    width: 100,
  },
];


const GridColumnMap = {
  tradeRequestGridColumns,
  giftHospitalityRequestGridColumns,
  restrictedSecurityGridColumns,
  CheckHoldingsGridColumns,
};

export default GridColumnMap;
