export const TRADE_APPROVAL_STATUS_CODE = {
  APPROVED: '1',
  REJECTED: '2',
  PENDING_APPROVAL: '3',
  FINAL_APPROVED: '4',
  EXPIRED: '5',
  PENDING_FINAL_APPROVAL: '6',
  UNKNOWN: '0',
};

const TRADE_APPROVAL_STATUS_LABEL = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING_APPROVAL: 'PendingApproval',
  FINAL_APPROVED: 'FinalApproved',
  EXPIRED: 'Expired',
  PENDING_FINAL_APPROVAL: 'PendingFinalApproval',
  UNKNOWN: 'Unknown',
};

export const TRADE_APPROVAL_STATUS_MAP = [
  // APPROVED
  {
    code: TRADE_APPROVAL_STATUS_CODE.APPROVED,
    label: TRADE_APPROVAL_STATUS_LABEL.APPROVED
  },
  // REJECTED
  {
    code: TRADE_APPROVAL_STATUS_CODE.REJECTED,
    label: TRADE_APPROVAL_STATUS_LABEL.REJECTED
  },
  // PENDING_APPROVED
  {
    code: TRADE_APPROVAL_STATUS_CODE.PENDING_APPROVAL,
    label: TRADE_APPROVAL_STATUS_LABEL.PENDING_APPROVAL
  },
  // FINAL_APPROVED
  {
    code: TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED,
    label: TRADE_APPROVAL_STATUS_LABEL.FINAL_APPROVED
  },
   // EXPIRED
   {
    code: TRADE_APPROVAL_STATUS_CODE.EXPIRED,
    label: TRADE_APPROVAL_STATUS_LABEL.EXPIRED
  },
   // PENDING_FINAL_APPROVED
   {
    code: TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL,
    label: TRADE_APPROVAL_STATUS_LABEL.PENDING_FINAL_APPROVAL
  },
  // UNKNOWN
  {
    code: TRADE_APPROVAL_STATUS_CODE.UNKNOWN,
    label: TRADE_APPROVAL_STATUS_LABEL.UNKNOWN
  },
];