import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';
import moment from 'moment';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { TRADE_REQUEST_STATUS_CODE } from '../../../../utils/PersonalTradeRequestStatus';
import { TRADE_APPROVAL_STATUS_CODE } from '../../../../utils/PersonalTradeApprovalStatus';
import PreviewTradeAttachRenderer from '../../../renderer/PreviewTradeAttachRenderer';
import StateSynchronizer from '../../../../utils/StateSynchronizer';
import { localStorageClient } from '../../../../utils/localStorageClient';

import './TradeRequest.css';

class TradeRequestGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.tradeRequestGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
      context: { componentParent: this },
      frameworkComponents: {
        previewTradeAttachRenderer: PreviewTradeAttachRenderer,
      },
      rowClassRules: {
        'unsubmitted': function(params) {
          return params.data.requestStatus !== TRADE_REQUEST_STATUS_CODE.SUBMITTED && !params.data.isAbandoned;
        },
        'submitted-unprocessed': function(params) {
          return params.data.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED && 
            params.data.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_APPROVAL && 
            !params.data.isAbandoned;
        },
        'not-upload-proof': function(params) {
          const tradeRequest = params.data;
          return !tradeRequest.isAbandoned &&
          tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED &&
          (tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED || tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL) &&
          moment().diff(moment(tradeRequest.tradeDateLabel), 'days') > tradeRequest.finishInDays && 
          (!tradeRequest.actualQty && tradeRequest.actualQty !== 0 ||  tradeRequest.actualQty !== 0 && !(tradeRequest.attachment));
        },
        'abandoned-application': function(params) {
          const tradeRequest = params.data;
          return tradeRequest.isAbandoned;
        },
      },
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var tradeRequestList = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectTradeRequest(tradeRequestList);
  }

  getRowNodeId = data => {
    return data.tradeId;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.closeToolPanel();

    // const COLUMNS_KEY = 'trade-request-report-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      tradeRequestArray,
      className = 'grid-wrapper',
      loadTradeRequestActionStatus
    } = this.props;

    const isLoading = loadTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    if (loadTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadTradeRequestActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={tradeRequestArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            getRowHeight={this.state.getRowHeight}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default TradeRequestGrid;