import React, {Component, useEffect} from 'react';

import { Button, message, Modal } from 'antd';
import moment from 'moment';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { TRADE_APPROVAL_STATUS_CODE } from '../../../../utils/PersonalTradeApprovalStatus';
import { TRADE_REQUEST_STATUS_CODE } from '../../../../utils/PersonalTradeRequestStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';

import TradeRequestGrid from './TradeRequestGrid';
import TradeRequestEditDialog from './TradeRequestEditDialog';
import CheckHoldingsPageDialog from './CheckHoldingsPageDialog';

import './TradeRequest.css';

const confirm = Modal.confirm;

class TradeRequestDashboard extends Component {

  componentWillUnmount() {
    const unSubmittedTraeRequestArray = this.filterUnSubmittedTradeRequest();

    if (unSubmittedTraeRequestArray.length > 0) {
      Modal.warning({
        title: 'Reminder',
        content: 'There are unsubmitted PA dealing application in the list. ',
      });
    }
  }

  filterUnSubmittedTradeRequest() {
    const {
      tradeRequestArray,
    } = this.props;

    const unSubmittedTraeRequestArray = tradeRequestArray.filter(tradeReqeust => 
        !tradeReqeust.isAbandoned &&
        tradeReqeust.requestStatus !== TRADE_REQUEST_STATUS_CODE.SUBMITTED);

    return unSubmittedTraeRequestArray;
  }

  componentDidMount() {
    this.props.loadTradeRequest();
    this.checkCanDoPa();
    // this.props.loadPersonalTradeFieldHistory(localStorageClient.getUserName());
    this.props.loadPersonalAccount(localStorageClient.getUserName());
  }

  componentDidUpdate() {
    const {
      submitTradeRequestActionStatus,
      batchDeleteTradeRequestsActionStatus,

      createTradeRequestActionStatus,
      updateTradeRequestActionStatus,
    } = this.props;

    // Submit Personal Trade Request 成功
    if (submitTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('PA dealing application are submitted successfully!');
      this.props.loadTradeRequest();
      this.props.initSubmitTradeRequestActionStatus();
    }
      
    // Submit Personal Trade Request 失败
    if (submitTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('PA dealing application are submitted failed!');
      this.props.initSubmitTradeRequestActionStatus();
    }

    // Batch Delete Personal Trade Request 成功
    if (batchDeleteTradeRequestsActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('PA dealing application are deleted successfully!');
      this.props.loadTradeRequest();
      this.props.initBatchDeleteTradeRequestActionStatus();
    }
      
    // Batch Delete Personal Trade Request 失败
    if (batchDeleteTradeRequestsActionStatus === ACTION_STATUS.ERROR) {
      message.error('PA dealing application are deleted failed!');
      this.props.initBatchDeleteTradeRequestActionStatus();
    }

    // Create Personal Trade Request 成功
    if (createTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Personal Trade Request is created successfully!');
      this.props.loadTradeRequest();
      
      // this.props.loadPersonalTradeFieldHistory(localStorageClient.getUserName());
      this.props.loadPersonalAccount(localStorageClient.getUserName());
      this.props.initCreateTradeRequestActionStatus();
      
      this.props.resetTradeRequestEditModalDialog();
      this.props.resetTradeRequestCloneEditModalDialog();
    }
      
    // Create Personal Trade Request 失败
    if (createTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Personal Trade Request is created failed!');
      this.props.initCreateTradeRequestActionStatus();
    }

    // ======================== Update ========================
    // Update Personal Trade Request 成功
    if (updateTradeRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Personal Trade Request is updated successfully!');
      this.props.loadTradeRequest();
      this.props.initUpdateTradeRequestActionStatus();
      
      this.props.resetTradeRequestEditModalDialog();
    }
      
    // Update Personal Trade Request 失败
    if (updateTradeRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Personal Trade Request is updated failed!');
      this.props.initUpdateTradeRequestActionStatus();
    }
  }

  checkCanDoPa = () => {
    this.props.checkCanDoPa();
  }

  openTradeRequestEditModalDialog(mode, trade) {
    this.props.openTradeRequestEditModalDialog(mode, trade);
    this.props.startTrigger30DayHoldingCheckInfoStatus();
  }

  addButtonOnClicked = () => {
    const {
      tradeRequestArray,
      checkStatus,
    } = this.props;

    //存在未完成的季度申报或者初始申报
    if (!checkStatus) {
      Modal.error({
        title: 'Reminder',
        content: 'You should completed your declaration first',
      });
      return;
    }

    // 有效交易(已提交已批准) 两日内未提交证明
    const filterTradeRequestList = tradeRequestArray.filter(tradeRequest => 
        !tradeRequest.isAbandoned &&
        tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED &&
        (tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED || tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL) &&
        moment().diff(moment(tradeRequest.tradeDateLabel), 'days') > tradeRequest.finishInDays && 
        (!tradeRequest.actualQty && tradeRequest.actualQty !== 0 || tradeRequest.actualQty !== 0 && !(tradeRequest.attachment)));


    if (filterTradeRequestList.length > 0) {
      Modal.error({
        title: 'Reminder',
        content: 'You cannot submit any new PA dealing application as you did not complete your trade confirmation(s) on your previous application. ',
      });
      return;
    }

    this.openTradeRequestEditModalDialog('ADD', null);
  }

  editButtonOnClicked = () => {
    const {
      selectedTradeRequestArray
    } = this.props;

    if (selectedTradeRequestArray.length !== 1) {
      message.warning("Please select a PA dealing application!");
      return;
    }

    let filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot edit an abandoned PA dealing application!");
      return;
    }

    filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot edit a submitted PA dealing application that have been final approved!");
      return;
    } 
    this.openTradeRequestEditModalDialog('EDIT', selectedTradeRequestArray[0]);
  }

  deleteButtonOnClicked = () => {
    const {
      selectedTradeRequestArray
    } = this.props;

    if (selectedTradeRequestArray.length === 0) {
      message.warning("Please select a PA dealing application at least!");
      return;
    }

    let filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot delete a PA dealing application that have been submitted!");
      return;
    } 

    filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED || 
      tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot delete a PA dealing application that have been approved!");
      return;
    } 

    filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.FINAL_APPROVED);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot delete a PA dealing application that have been final approved!");
      return;
    } 

    filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.REJECTED);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot delete a PA dealing application that have been rejected!");
      return;
    } 

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the PA dealing application?',
      content: '',
      onOk() {
        const tradeRequestIdList = selectedTradeRequestArray.map(tradeRequest => tradeRequest.tradeId);

        that.props.batchDeleteTradeRequest(tradeRequestIdList);
      },
      onCancel() {},
    });
  }

  submitButtonOnClicked = () => {
    const {
      selectedTradeRequestArray
    } = this.props;

    if (selectedTradeRequestArray.length === 0) {
      message.warning("Please select a PA dealing application at least!");
      return;
    }

    let filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.isAbandoned);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot submit an abandoned PA dealing application!");
      return;
    }

    // 已经提交的不能再次提交（以免给合规组重复发邮件）
    filterTradeRequestArray = selectedTradeRequestArray.filter(tradeRequest => tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED);
    if (filterTradeRequestArray.length > 0) {
      message.warning("You cannot submit a PA dealing application that has already been submitted. Please use 'Copy' if you want to submit a new one.");
      return;
    } 

    let that = this;
    confirm({
      title: 'Are you sure you want to submit the PA dealing application?',
      content: '',
      onOk() {
        const tradeRequestIdList = selectedTradeRequestArray.map(tradeRequest => tradeRequest.tradeId);

        that.props.submitTradeRequest(tradeRequestIdList);
      },
      onCancel() {},
    });
  }

  // 克隆一份交易
  cloneButtonOnClicked = () => {
    const {
      selectedTradeRequestArray
    } = this.props;

    const {
      tradeRequestArray,
      checkStatus,
    } = this.props;

    //存在未完成的季度申报或者初始申报
    if (!checkStatus) {
      Modal.error({
        title: 'Reminder',
        content: 'You should completed your declaration first',
      });
      return;
    }

    if (selectedTradeRequestArray.length !== 1) {
      message.warning("Please select a PA dealing application!");
      return;
    }

    // 有效交易(已提交已批准) 两日内未提交证明
    const filterTradeRequestList = tradeRequestArray.filter(tradeRequest => 
        !tradeRequest.isAbandoned &&
        tradeRequest.requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED &&
        (tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.APPROVED || tradeRequest.approvalStatus === TRADE_APPROVAL_STATUS_CODE.PENDING_FINAL_APPROVAL) &&
        moment().diff(moment(tradeRequest.tradeDateLabel), 'days') > tradeRequest.finishInDays && 
        (!tradeRequest.actualQty && tradeRequest.actualQty !== 0 ||tradeRequest.actualQty !== 0 && !(tradeRequest.attachment)));

    if (filterTradeRequestList.length > 0) {
      Modal.error({
        title: 'Reminder',
        content: 'You cannot submit any new PA dealing application as you did not complete your trade confirmation(s) on your previous application. ',
      });
      return;
    }
    this.openTradeRequestEditModalDialog('COPY', selectedTradeRequestArray[0]);
  }

  refreshButtonOnClicked = () => {
    this.props.loadTradeRequest();
  }
  
  render() {
    const {
      loadTradeRequestActionStatus,
      submitTradeRequestActionStatus,
      batchDeleteTradeRequestsActionStatus,
    } = this.props;

    const loadBtnIsLoading = loadTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    const submitBtnIsLoading = submitTradeRequestActionStatus === ACTION_STATUS.LOGINING;
    const deleteBtnIsLoading = batchDeleteTradeRequestsActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="tradeDashboardWrapper">
        
        <div className='tradeOperationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button>

          <Button 
            onClick={this.deleteButtonOnClicked} 
            size='small'
            loading={deleteBtnIsLoading}
            className='negative-button'>
            Del
          </Button>

          <Button 
            onClick={this.submitButtonOnClicked} 
            size='small'
            loading={submitBtnIsLoading}
            type='primary'>
            Submit
          </Button>

          <Button 
            onClick={this.cloneButtonOnClicked} 
            size='small'
            type='primary'>
            Copy
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Submit Trade Confo
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='tradeGrid'>
          <TradeRequestGrid {...this.props} />
        </div>

        <TradeRequestEditDialog {...this.props} />

        <CheckHoldingsPageDialog {...this.props} />

      </div>
    );
  }
}

export default TradeRequestDashboard;