import React from 'react';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from '../../../utils/GridColumnMap';
import { SIDE_TYPE_CODE } from '../../../utils/SideTypeUtil';
import { PRICE_TYPE_CODE } from '../../../utils/PriceTypeUtil';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import { Select } from 'antd';
import moment from 'moment';

import {
  // Load Trade Request
  START_LOAD_TRADE_REQUEST,
  LOAD_TRADE_REQUEST_SUCCESS,
  LOAD_TRADE_REQUEST_FAILURE,
  INIT_LOAD_TRADE_REQUEST_ACTION_STATUS,

  // Select Trade Request
  SELECT_TRADE_REQUEST,

  // Open/Reset Trade Request Modal Dialog
  TRADE_REQUEST_MODAL_DIALOG_OPEN,
  TRADE_REQUEST_MODAL_DIALOG_RESET,

  // Trade Request Edit Change Input
  TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Create Trade Request
  START_CREATE_TRADE_REQUEST,
  CREATE_TRADE_REQUEST_SUCCESS,
  CREATE_TRADE_REQUEST_FAILURE,
  INIT_CREATE_TRADE_REQUEST_ACTION_STATUS,

  // Update Trade Request
  START_UPDATE_TRADE_REQUEST,
  UPDATE_TRADE_REQUEST_SUCCESS,
  UPDATE_TRADE_REQUEST_FAILURE,
  INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS,

  // Batch Delete Trade Request
  START_BATCH_DELETE_TRADE_REQUEST,
  BATCH_DELETE_TRADE_REQUEST_SUCCESS,
  BATCH_DELETE_TRADE_REQUEST_FAILURE,
  INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS,

  // Submit Trade Request
  START_SUBMIT_TRADE_REQUEST,
  SUBMIT_TRADE_REQUEST_SUCCESS,
  SUBMIT_TRADE_REQUEST_FAILURE,
  INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Load Personal Trade Field History
  START_LOAD_TRADE_FIELD_HISTORY,
  LOAD_TRADE_FIELD_HISTORY_SUCCESS,
  LOAD_TRADE_FIELD_HISTORY_FAILURE,
  INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS,

  // Load Exchange Code
  START_LOAD_EXCHANGE_CODE,
  LOAD_EXCHANGE_CODE_SUCCESS,
  LOAD_EXCHANGE_CODE_FAILURE,
  INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS,

  // Clear exchageCodeArray exchageCodeOptions
  CLEAR_EXCHANGE_CODES,

  // Exchange Code Select Changed
  EXCHANGE_CODE_SELECT_CHANGED,

  // Open/Reset Trade Request Clone Edit Modal Dialog
  TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN,
  TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET,

  // Trade Request Clone Edit Change Input
  TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT,

  // load personal account
  START_LOAD_PERSONAL_ACCOUNT,
  LOAD_PERSONAL_ACCOUNT_SUCCESS,
  LOAD_PERSONAL_ACCOUNT_FAILURE,
  INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,

  // 30天持仓相关预检查
  START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
  QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
  RESET_30_DAY_HOLDING_CHECK_INFO,
  START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,

  // openCheckHoldingsPage
  OPEN_CHECK_HOLDINGS_PAGE,
  RESET_CHECK_HOLDINGS_PAGE,
  START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT,
  QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS,
  QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE,
  INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS,
  SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM,
    
  //check rule
  CHECK_CAN_DO_PA_SUCCESS,
  CHECK_CAN_DO_PA_FAILURE,
} from './tradeRequestConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
} from '../home/homeConstants';


const Option = Select.Option;

const initialState = {
  // Grdi Columns
  tradeRequestGridColumns: GridColumnMap.tradeRequestGridColumns,
  CheckHoldingsGridColumns: GridColumnMap.CheckHoldingsGridColumns,

  // Trade Request
  tradeRequestArray: [],

  // Selected Trade Request
  selectedTradeRequestArray: [],

  // Trade Request Edit
  tradeRequestEditControlModal: {
    isOpened: false,
    mode: 'ADD',
    respErrMsg: '',
    fields: {
      accountGuid: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      stockMarket: '',
      side: SIDE_TYPE_CODE.BUY,
      priceType: PRICE_TYPE_CODE.MARKET,
      price: '',
      applyQty: '',
      actualQty: '',
      amount: '',
      currency: '',
      tradeDate: '',
      holdingDays: '',
      remark: '',
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
      requestStatus: '',
      isPassive: false
    }
  },

  // load/create/update/delete/submit Trade Request
  loadTradeRequestActionStatus: ACTION_STATUS.READY,
  createTradeRequestActionStatus: ACTION_STATUS.READY,
  updateTradeRequestActionStatus: ACTION_STATUS.READY,
  batchDeleteTradeRequestsActionStatus: ACTION_STATUS.READY,
  submitTradeRequestActionStatus: ACTION_STATUS.READY,

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Load BBG Tickers Action Status
  loadBbgTickersActionStatus: ACTION_STATUS.READY,

  // Load Personal Trade Field History
  loadTradeFieldHistoryActionStatus: ACTION_STATUS.READY,
  personalTradeFieldHistory: {
    accountNameList: [],
    brokerAccountList: [],
    accountNoList: [],
    // Option
    accountNameOptions: [],
    brokerAccountOptions: [],
    accountNoOptions: [],
  },

  // Load Exchange Code
  loadExchangeCodeActionStatus: ACTION_STATUS.READY,
  exchageCodeArray: [],
  exchageCodeOptions: [],

  // 附件操作URL
  tradeAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_PERSONAL_TRADE_ATTACHMENT,
  tradeAttachmentPreviewUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PREVIEW_PERSONAL_TRADE_ATTACHMENT,
  tradeAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_PERSONAL_TRADE_ATTACHMENT,

  // 30天持仓相关预检查
  resultOf30DayHoldingCheckInfo: {
    allQuantity: '',
    in30DaysBuyQty: '',
    approveWaitSellQty: '',
    submittedNotAbandonedQty: '',
    availableForSellQty: '',
  },

  // checkHoldingPageModal
  checkHoldingsPageModal: {
    isOpen: false,
    accountGuid: '',
  },

  //check rule
  checkStatus: false,
} 


// Load Trade Request
function startLoadTradeRequest(state) {
  return {
    ...state,
    loadTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadTradeRequestSuccess(state, resp) {
  return {
    ...state,
    loadTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
    tradeRequestArray: resp.data,
  };
}

function loadTradeRequestFailure(state, err) {
  return {
    ...state,
    loadTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadTradeRequestActionStatus(state) {
  return {
    ...state,
    loadTradeRequestActionStatus: ACTION_STATUS.READY,
    selectedTradeRequestArray: [],
  };
}

// Select Trade Request
function selectTradeRequest(state, payload) {
  return {
    ...state,
    selectedTradeRequestArray: payload,
  };
}

// Open/Reset Trade Request Modal Dialog
function openTradeRequestEditModalDialog(state, {mode, tradeRequest}) {
  if (mode === 'ADD') {
    return {
      ...state,
      tradeRequestEditControlModal: {
        ...state.tradeRequestEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.tradeRequestEditControlModal.fields,
          tradeDate: moment().format('YYYY-MM-DD'),
          isPassive: 'false'
        },
      },
    };
  } else {
    const tradeAttachmentDownloadUrl = state.tradeAttachmentDownloadUrl;

    const fileList = [];
    const attachment = tradeRequest.attachment;
    const attachmentOriginName = tradeRequest.attachmentOriginName;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: tradeAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
    }

    // COPY时无需提供的参数
    const currId = mode === 'EDIT' ? tradeRequest.tradeId : null;
    const currTradeDay =  mode === 'EDIT' ? tradeRequest.tradeDate : moment().format('YYYY-MM-DD');
    const currAttachment = mode === 'EDIT' ? tradeRequest.attachment : '';
    const currAttachementOriginName = mode === 'EDIT' ? tradeRequest.attachmentOriginName: '';
    const currActualQty = mode === 'EDIT' ? tradeRequest.actualQty: '';

    return {
      ...state,
      tradeRequestEditControlModal: {
        ...state.tradeRequestEditControlModal,
        isOpened: true,
        mode,
        fields: {
          ...state.tradeRequestEditControlModal.fields,
          id: currId,
          accountGuid: tradeRequest.accountGuid,
          securityId: tradeRequest.securityId,
          bbgTicker: tradeRequest.bbgTicker,
          secName: tradeRequest.secName,
          stockMarket: tradeRequest.stockMarket,
          side: tradeRequest.side,
          priceType: tradeRequest.priceType,
          price: tradeRequest.price,
          applyQty: tradeRequest.applyQty,
          actualQty: currActualQty,
          amount: tradeRequest.amount,
          currency: tradeRequest.currency,
          tradeDate: currTradeDay,
          holdingDays: tradeRequest.holdingDays,
          remark: tradeRequest.remark,
          attachment: currAttachment,
          attachmentOriginName: currAttachementOriginName,
          fileList,
          requestStatus: tradeRequest.requestStatus,
          category: tradeRequest.category,
          isPassive: tradeRequest.isPassive ? 'true' : 'false'
        }
      },
    };
  }
}

function resetTradeRequestEditModalDialog(state) {
  return {
    ...state,
    tradeRequestEditControlModal: initialState.tradeRequestEditControlModal,
  };
}

// Trade Request Edit Change Input
function tradeRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.tradeRequestEditControlModal.fields,
    [name]: value,
  };
  return {
    ...state,
    tradeRequestEditControlModal: {
      ...state.tradeRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// =============================================================
// Create Trade Request
function startCreateTradeRequest(state) {
  return {
    ...state,
    createTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createTradeRequestSuccess(state, resp) {
  return {
    ...state,
    createTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createTradeRequestFailure(state, err) {
  return {
    ...state,
    createTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initCreateTradeRequestActionStatus(state) {
  return {
    ...state,
    createTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Update Trade Request
function startUpdateTradeRequest(state) {
  return {
    ...state,
    updateTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateTradeRequestSuccess(state, resp) {
  return {
    ...state,
    updateTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateTradeRequestFailure(state, err) {
  return {
    ...state,
    updateTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initUpdateTradeRequestActionStatus(state) {
  return {
    ...state,
    updateTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Trade Request
function startBatchDeleteTradeRequest(state) {
  return {
    ...state,
    batchDeleteTradeRequestsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteTradeRequestSuccess(state, resp) {
  return {
    ...state,
    batchDeleteTradeRequestsActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteTradeRequestFailure(state, err) {
  return {
    ...state,
    batchDeleteTradeRequestsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteTradeRequestActionStatus(state) {
  return {
    ...state,
    batchDeleteTradeRequestsActionStatus: ACTION_STATUS.READY,
  };
}

// Submit Trade Request
function startSubmitTradeRequest(state) {
  return {
    ...state,
    submitTradeRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitTradeRequestSuccess(state, resp) {
  return {
    ...state,
    submitTradeRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function submitTradeRequestFailure(state, err) {
  return {
    ...state,
    submitTradeRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initSubmitTradeRequestActionStatus(state) {
  return {
    ...state,
    submitTradeRequestActionStatus: ACTION_STATUS.READY,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  let securityTickerArray = securityTickerData.securityTickerList;

  return {
    ...state,
    securityTickerData,
    securityTickerArray,
  };
}


// =================================================================
// Load BBG Tickers
function startLoadBBGTickers(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadBbgTickersFailure(state, err) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadBbgTickersActionStatus(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}


// Clear bbgTickerArray bbgTickerOptions
function clearBbgTickers(state) {
  return {
    ...state,
    tradeRequestEditControlModal: {
      ...state.tradeRequestEditControlModal,
      fields: {
        ...state.tradeRequestEditControlModal.fields,
        securityId: '',
        secName: '',
        currency: '',
        stockMarket: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// BBG Ticker Select Changed
function bbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;

  let securityId = '';
  let securityName = '';
  let currency = '';
  let stockMarket = '';

  if (!!bbgTicker) {
    const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => !!securityTicker.bbgTicker && securityTicker.bbgTicker.toUpperCase() === bbgTicker.toUpperCase());

    if (filterSecurityTickerArray.length > 0) {
      const securityTicker = filterSecurityTickerArray[0];
      securityId = securityTicker.securityId;
      securityName = securityTicker.securityName;
      currency = securityTicker.currency;
      stockMarket = securityTicker.exchangeCode;
    }
  }

  const fields = state.tradeRequestEditControlModal.fields;

  securityName = securityName === '' ? fields.secName : securityName;
  currency = currency === '' ? fields.currency : currency;
  stockMarket = stockMarket === '' ? fields.stockMarket : stockMarket;

  return {
    ...state,
    tradeRequestEditControlModal: {
      ...state.tradeRequestEditControlModal,
      fields: {
        ...state.tradeRequestEditControlModal.fields,
        securityId,
        secName: securityName,
        currency,
        stockMarket,
      },
    },
  };
}

// Load Personal Trade Field History
function startLoadPersonalTradeFieldHistory(state) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadPersonalTradeFieldHistorySuccess(state, resp) {
  const accountNameList = resp.data.accountNameList;
  const brokerAccountList = resp.data.brokerAccountList;
  const accountNoList = resp.data.accountNoList;

  // Option
  let accountNameOptions = accountNameList.map(accountName => <Option key={accountName}>{accountName}</Option>);
  let brokerAccountOptions = brokerAccountList.map(brokerAccount => <Option key={brokerAccount}>{brokerAccount}</Option>);
  let accountNoOptions = accountNoList.map(accountNo => <Option key={accountNo}>{accountNo}</Option>);

  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.SUCCESS,
    personalTradeFieldHistory: {
      accountNameList,
      brokerAccountList,
      accountNoList,
      // Option
      accountNameOptions,
      brokerAccountOptions,
      accountNoOptions,
    },
  };
}

function loadPersonalTradeFieldHistoryFailure(state, err) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.ERROR,
    personalTradeFieldHistory: initialState.personalTradeFieldHistory,
  };
}

function initLoadPersonalTradeFieldHistoryActionStatus(state) {
  return {
    ...state,
    loadTradeFieldHistoryActionStatus: ACTION_STATUS.READY,
  };
}

// Load Exchange Code
function startLoadExchangeCode(state) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadExchangeCodeSuccess(state, resp) {
  const exchageCodeArray = resp.data;

  const exchageCodeOptions = exchageCodeArray.map(exchangeCode => <Option key={exchangeCode}>{exchangeCode}</Option>);

  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.SUCCESS,
    exchageCodeArray,
    exchageCodeOptions,
  };
}

function loadExchangeCodeFailure(state, err) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadExchangeCodeActionStatus(state) {
  return {
    ...state,
    loadExchangeCodeActionStatus: ACTION_STATUS.READY,
  };
}

// Clear exchageCodeArray exchageCodeOptions
function clearExchangeCodes(state) {
  return {
    ...state,
    exchageCodeArray: [],
    exchageCodeOptions: [],
  };
}

// Exchange Code Select Changed
function exchangeCodeSelectChanged(state, exchangeCode) {
  return {
    ...state,
  };
}

// Open/Reset Trade Request Clone Edit Modal Dialog
// function openTradeRequestCloneEditModalDialog(state, {tradeRequest}) {
//   return {
//     ...state,
//     tradeRequestCloneEditControlModal: {
//       ...state.tradeRequestCloneEditControlModal,
//       isOpened: true,
//       fields: {
//         ...state.tradeRequestCloneEditControlModal.fields,
//         accountName: tradeRequest.accountName,
//         brokerAccount: tradeRequest.brokerAccount,
//         accountNo: tradeRequest.accountNo,
//         securityId: tradeRequest.securityId,
//         bbgTicker: tradeRequest.bbgTicker,
//         secName: tradeRequest.secName,
//         stockMarket: tradeRequest.stockMarket,
//         side: tradeRequest.side,
//         priceType: tradeRequest.priceType,
//         // price: tradeRequest.price,
//         // applyQty: tradeRequest.applyQty,
//         // actualQty: tradeRequest.actualQty,
//         currency: tradeRequest.currency,
//         // tradeDate: tradeRequest.tradeDate,
//         // holdingDays: tradeRequest.holdingDays,
//         // remark: tradeRequest.remark,
//         // attachment: tradeRequest.attachment,
//         // attachmentOriginName: tradeRequest.attachmentOriginName,
//         // fileList: [],
//       }
//     },
//   };
// }

// function resetTradeRequestCloneEditModalDialog(state) {
//   return {
//     ...state,
//     tradeRequestCloneEditControlModal: initialState.tradeRequestCloneEditControlModal,
//   };
// }

// Trade Request Clone Edit Change Input
function tradeRequestCloneEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.tradeRequestCloneEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    tradeRequestCloneEditControlModal: {
      ...state.tradeRequestCloneEditControlModal,
      fields: updatedFields,
    },
  };
}

// load personal account
function startLoadPersonalAccount(state) {
  return {
    ...state,
    loadPersonalAccountStatus: ACTION_STATUS.LOGINING,
  };
}
function loadPersonalAccountSuccess(state, resp) {
  const personalAccountOptions = resp.data.map(e=><Option key={e.guid}>{e.accountName} / {e.brokerAccount} / {e.accountNo}</Option>);
  return {
    ...state,
    loadPersonalAccountStatus: ACTION_STATUS.SUCCESS,
    personalAccountOptions,
  };
}
function loadPersonalAccountFailure(state, err) {
  return {
    ...state,
    loadPersonalAccountStatus: ACTION_STATUS.ERROR,
  };
}
function initLoadPersonalAccountActionStatus(state) {
  return {
    ...state,
    loadPersonalAccountStatus: ACTION_STATUS.READY,
  };
}


// 30天持仓相关预检查
function startQuery30DayHoldingCheckInfo(state) {
  return {
    ...state,
    query30DayHoldingCheckInfoStatus: ACTION_STATUS.LOGINING,
  };
}
function query30DayHoldingCheckInfoSuccess(state, resp) {
  if(!!resp && !!resp.data && !!resp.data.resultOf30DaysHolding && resp.data.resultOf30DaysHolding.length > 0) {
    const {
      allQuantity,
      in30DaysBuyQty,
      approveWaitSellQty,
      submittedNotAbandonedQty,
    } = resp.data.resultOf30DaysHolding[0];
    let availableForSellQty = '';
    if(allQuantity!== undefined && allQuantity !== null && allQuantity !== '' && !isNaN(allQuantity)
        && in30DaysBuyQty!== undefined && in30DaysBuyQty !== null && in30DaysBuyQty !== '' && !isNaN(in30DaysBuyQty)
        && approveWaitSellQty!== undefined && approveWaitSellQty !== null && approveWaitSellQty !== '' && !isNaN(approveWaitSellQty)
        && submittedNotAbandonedQty!== undefined && submittedNotAbandonedQty !== null && submittedNotAbandonedQty !== '' && !isNaN(submittedNotAbandonedQty)
    ) {
            availableForSellQty = allQuantity - in30DaysBuyQty - approveWaitSellQty - submittedNotAbandonedQty;  
    }

    return {
      ...state,
      query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      resultOf30DayHoldingCheckInfo: {
        allQuantity,
        in30DaysBuyQty,
        approveWaitSellQty,
        submittedNotAbandonedQty,
        availableForSellQty,
      }
    };
  } else {
    return {
      ...state,
      query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
      resultOf30DayHoldingCheckInfo: {
        ...initialState.resultOf30DayHoldingCheckInfo,
      }
    };
  }
}
function query30DayHoldingCheckInfoFailure(state, err) {
  return {
    ...state,
    query30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    resultOf30DayHoldingCheckInfo: {
      ...initialState.resultOf30DayHoldingCheckInfo,
    }
  };
}
function reset30DayHoldingCheckInfo(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
    resultOf30DayHoldingCheckInfo: {
      ...initialState.resultOf30DayHoldingCheckInfo,
    }
  };
}
function startTrigger30DayHoldingCheckInfoStatus(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.LOGINING,
  };
}
function stopTrigger30DayHoldingCheckInfoStatus(state) {
  return {
    ...state,
    trigger30DayHoldingCheckInfoStatus: ACTION_STATUS.READY,
  };
}


// openCheckHoldingsPage
function openCheckHoldingsPage(state, payload) {
  return {
    ...state,
    checkHoldingsPageModal: {
      ...state.checkHoldingsPageModal,
      isOpen: true,
      accountGuid: payload.accountGuid,
    }
  };
}
function resetCheckHoldingsPage(state) {
  return {
    ...state,
    checkHoldingsPageModal: initialState.checkHoldingsPageModal,
    queryHoldingsForCurrAccountResp: null,
  };
}


function startQueryHoldingsForCurrAccount(state) {
  return {
    ...state,
    queryHoldingsForCurrAccountStatus: ACTION_STATUS.LOGINING,
  };
}
function queryHoldingsForCurrAccountSuccess(state, resp) {
  return {
    ...state,
    queryHoldingsForCurrAccountStatus: ACTION_STATUS.SUCCESS,
    queryHoldingsForCurrAccountResp: resp,
  };
}
function queryHoldingsForCurrAccountFailure(state, err) {
  return {
    ...state,
    queryHoldingsForCurrAccountStatus: ACTION_STATUS.ERROR,
    queryHoldingsForCurrAccountResp: err,
  };
}
function initQueryHoldingsForCurrAccountActionStatus(state) {
  return {
    ...state,
    queryHoldingsForCurrAccountStatus: ACTION_STATUS.READY,
  };
}


function setTradeRequestEditDialogForm(state, form) {
  return {
    ...state,
    tradeRequestEditDialogForm: form,
  };
}

function checkCanDoPaSuccess(state, resp) {
  return {
    ...state,
    checkStatus: resp.data,
  };
}

function checkCanDoPaFailure(state, err) {
  return {
    ...state,
  };
}

export default createReducer(initialState, {
  // Load Trade Request
  [START_LOAD_TRADE_REQUEST]: startLoadTradeRequest,
  [LOAD_TRADE_REQUEST_SUCCESS]: loadTradeRequestSuccess,
  [LOAD_TRADE_REQUEST_FAILURE]: loadTradeRequestFailure,
  [INIT_LOAD_TRADE_REQUEST_ACTION_STATUS]: initLoadTradeRequestActionStatus,

  // Select Trade Request
  [SELECT_TRADE_REQUEST]: selectTradeRequest,

  // Open/Reset Trade Request Modal Dialog
  [TRADE_REQUEST_MODAL_DIALOG_OPEN]: openTradeRequestEditModalDialog,
  [TRADE_REQUEST_MODAL_DIALOG_RESET]: resetTradeRequestEditModalDialog,

  // Trade Request Edit Change Input
  [TRADE_REQUEST_EDIT_CHANGE_INPUT]: tradeRequestEditChangeInput,

  // Create Trade Request
  [START_CREATE_TRADE_REQUEST]: startCreateTradeRequest,
  [CREATE_TRADE_REQUEST_SUCCESS]: createTradeRequestSuccess,
  [CREATE_TRADE_REQUEST_FAILURE]: createTradeRequestFailure,
  [INIT_CREATE_TRADE_REQUEST_ACTION_STATUS]: initCreateTradeRequestActionStatus,

  // Update Trade Request
  [START_UPDATE_TRADE_REQUEST]: startUpdateTradeRequest,
  [UPDATE_TRADE_REQUEST_SUCCESS]: updateTradeRequestSuccess,
  [UPDATE_TRADE_REQUEST_FAILURE]: updateTradeRequestFailure,
  [INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS]: initUpdateTradeRequestActionStatus,

  // Batch Delete Trade Request
  [START_BATCH_DELETE_TRADE_REQUEST]: startBatchDeleteTradeRequest,
  [BATCH_DELETE_TRADE_REQUEST_SUCCESS]: batchDeleteTradeRequestSuccess,
  [BATCH_DELETE_TRADE_REQUEST_FAILURE]: batchDeleteTradeRequestFailure,
  [INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS]: initBatchDeleteTradeRequestActionStatus,

  // Submit Trade Request
  [START_SUBMIT_TRADE_REQUEST]: startSubmitTradeRequest,
  [SUBMIT_TRADE_REQUEST_SUCCESS]: submitTradeRequestSuccess,
  [SUBMIT_TRADE_REQUEST_FAILURE]: submitTradeRequestFailure,
  [INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS]: initSubmitTradeRequestActionStatus,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Load BBG Tickers
  [START_LOAD_BBG_TICKERS]: startLoadBBGTickers,
  [LOAD_BBG_TICKERS_SUCCESS]: loadBbgTickersSuccess,
  [LOAD_BBG_TICKERS_FAILURE]: loadBbgTickersFailure,
  [INIT_LOAD_BBG_TICKERS_ACTION_STATUS]: initLoadBbgTickersActionStatus,

  // Clear bbgTickerArray bbgTickerOptions
  [CLEAR_BBG_TICKERS]: clearBbgTickers,

  // BBG Ticker Select Changed
  [BBG_TICKER_SELECT_CHANGED]: bbgTickerSelectChanged,

  // Load Personal Trade Field History
  [START_LOAD_TRADE_FIELD_HISTORY]: startLoadPersonalTradeFieldHistory,
  [LOAD_TRADE_FIELD_HISTORY_SUCCESS]: loadPersonalTradeFieldHistorySuccess,
  [LOAD_TRADE_FIELD_HISTORY_FAILURE]: loadPersonalTradeFieldHistoryFailure,
  [INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS]: initLoadPersonalTradeFieldHistoryActionStatus,

  // Load Exchange Code
  [START_LOAD_EXCHANGE_CODE]: startLoadExchangeCode,
  [LOAD_EXCHANGE_CODE_SUCCESS]: loadExchangeCodeSuccess,
  [LOAD_EXCHANGE_CODE_FAILURE]: loadExchangeCodeFailure,
  [INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS]: initLoadExchangeCodeActionStatus,

  // Clear exchageCodeArray exchageCodeOptions
  [CLEAR_EXCHANGE_CODES]: clearExchangeCodes,

  // Exchange Code Select Changed
  [EXCHANGE_CODE_SELECT_CHANGED]: exchangeCodeSelectChanged,

  // Open/Reset Trade Request Clone Edit Modal Dialog
  // [TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN]: openTradeRequestCloneEditModalDialog,
  // [TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET]: resetTradeRequestCloneEditModalDialog,

  // Trade Request Clone Edit Change Input
  [TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT]: tradeRequestCloneEditChangeInput,

  // load personal account
  [START_LOAD_PERSONAL_ACCOUNT]: startLoadPersonalAccount,
  [LOAD_PERSONAL_ACCOUNT_SUCCESS]: loadPersonalAccountSuccess,
  [LOAD_PERSONAL_ACCOUNT_FAILURE]: loadPersonalAccountFailure,
  [INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS]: initLoadPersonalAccountActionStatus,

  // 30天持仓相关预检查
  [START_QUERY_30_DAY_HOLDING_CHECK_INFO]: startQuery30DayHoldingCheckInfo,
  [QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS]: query30DayHoldingCheckInfoSuccess,
  [QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE]: query30DayHoldingCheckInfoFailure,
  [RESET_30_DAY_HOLDING_CHECK_INFO]: reset30DayHoldingCheckInfo,
  [START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS]: startTrigger30DayHoldingCheckInfoStatus,
  [STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS]: stopTrigger30DayHoldingCheckInfoStatus,


  // openCheckHoldingsPage
  [OPEN_CHECK_HOLDINGS_PAGE]: openCheckHoldingsPage,
  [RESET_CHECK_HOLDINGS_PAGE]: resetCheckHoldingsPage,
  [START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT]: startQueryHoldingsForCurrAccount,
  [QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS]: queryHoldingsForCurrAccountSuccess,
  [QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE]: queryHoldingsForCurrAccountFailure,
  [INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS]: initQueryHoldingsForCurrAccountActionStatus,
  [SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM]: setTradeRequestEditDialogForm,

  //check rule
  [CHECK_CAN_DO_PA_SUCCESS]: checkCanDoPaSuccess,
  [CHECK_CAN_DO_PA_FAILURE]: checkCanDoPaFailure,
});